<template>
  <div class="content">
    <!-- <div class="menu">
      <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">待支付</el-menu-item>
        <el-menu-item index="2">打单中</el-menu-item>
        <el-menu-item index="3">已出单</el-menu-item>
        <el-menu-item index="4">异常</el-menu-item>
        <el-menu-item index="5">取消</el-menu-item>
      </el-menu>
    </div> -->
    <div class="table">
      <el-table :data="orderData.data" style="width: 100%"
        :header-cell-style="{ background: '#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500' }"
        :row-style="{ height: '78px' }" @selection-change="handleSelectionChange" @select="orderSelect">
        <el-table-column type="selection" width="55"
          :selectable="(row, index) => (row.order_status === '3' || row.print_status === '0') && (tab ? row.deliver_tag === tab ? true : false : true)">
        </el-table-column>
        <el-table-column label="打印状态" width="160">
          <template slot-scope="scope"><span>{{ scope.row.print_status === '0' ? '未打印' : '已打印' }}</span></template>
        </el-table-column>
        <el-table-column label="序号" width="55" fixed>
          <template slot-scope="scope"><span>{{ scope.$index + 1 }}</span></template>
        </el-table-column>
        <el-table-column label="状态" width="68" fixed>
          <template slot-scope="scope">
            <!-- 待支付 -->
            <div v-if="scope.row.order_status === '1'" class="to-be-paid state"><span></span>待支付</div>
            <!-- 打单中 -->
            <div v-if="scope.row.order_status === '2'" class="in-order state"><span></span>打单中</div>
            <!-- 已出单 -->
            <div v-if="scope.row.order_status === '3'" class="order-issued state"><span></span>已出单</div>
            <!-- 异常 -->
            <div v-if="scope.row.order_status === '4'" class="abnormal state"><span></span>异常</div>
            <!-- 取消 -->
            <div v-if="scope.row.order_status === '5'" class="cancel state"><span></span>取消退款</div>
            <div v-if="scope.row.order_status === '6'" class="cancel state"><span></span>发货完成</div>
          </template>
        </el-table-column>
        <el-table-column prop="package_no" label="包裹号" width="230" fixed></el-table-column>
        <el-table-column prop="third_order_no" label="三方单号" width="180" fixed></el-table-column>
        <el-table-column prop="waybill_code" label="运单号" width="160" fixed></el-table-column>
        <el-table-column prop="goods_name" label="商品名" width="90"></el-table-column>
        <el-table-column label="商品图" width="100">
          <template slot-scope="scope">
            <div class="product-info">
              <img :src="scope.row.goods_images" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="总数量">
          <template slot-scope="scope">
            <div class="child-num">{{ scope.row.goods_num }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;件</div>
          </template>
        </el-table-column>
        <el-table-column prop="order_price" label="付款金额"></el-table-column>
        <el-table-column prop="lp_reissue_sn" label="上家单号" width="160"></el-table-column>
        <el-table-column prop="package_tips" label="备注"></el-table-column>
        <el-table-column label="创建时间" width="160">
          <template slot-scope="scope"><span>{{ timeConversion(scope.row.createtime) }}</span></template>
        </el-table-column>
        <el-table-column prop="receive_name" label="收货人" width="90"></el-table-column>
        <el-table-column prop="receive_phone" label="收货电话" width="115"></el-table-column>
        <el-table-column label="收货地址" width="320">
          <template slot-scope="scope">
            <span>{{ scope.row.receive_province + scope.row.receive_city + scope.row.receive_town +
        scope.row.receive_address
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="scope.row.order_status === '3' || scope.row.print_status === '0'" type="primary"
              @click="individuallyPrint(scope.row)">打印
            </el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="address" label="操作" width="200"> -->
        <!-- <template slot-scope="scope">
            <div class="handle">
              <div @click="payThis(scope.row)" v-if="scope.row.status === '1'">支付</div>
              <div @click="remove(scope.row.id)" v-if="scope.row.status === '1' || scope.row.status === '4'" class="remove">取消</div>
              <div v-if="scope.row.status !== '1'">
                <a :href="url + '/api/order/excel_order?id=' + scope.row.id">下载</a>
              </div>
              <div @click="eye(scope.row)">查看</div>
            </div>
          </template> -->
        <!-- </el-table-column> -->
      </el-table>
      <child-table :showDialog="showDialog" @closeDialog="closeDialog" :packageData="packageData"
        @updatePackageData="updatePackageData" :id="id" :showDownload="showDownload" />
      <div class="pagination">
        <el-pagination background @current-change="currentChange" :page-size="orderData.per_page"
          layout="prev, pager, next" :current-page="orderData.current_page" :total="orderData.total"></el-pagination>
      </div>
    </div>
    <!-- 选择打印机 -->
    <!-- 选择打印机 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div style='display: flex;flex-direction: column;'>
        <el-select v-model="value" placeholder="打印机">
          <el-option v-for="item in printers" :key="item.name" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
        <div style="padding: 5px;"></div>
        <el-select v-model="ddexptplValue" placeholder="打印模板" @change="ddexptplInfoChange">
          <el-option v-for="(item, index) in ddexptplInfo" :key="index" :label="item.standard_template_name"
            :value="JSON.stringify({ url: item.standard_template_url, image: item.perview_url })">
          </el-option>
        </el-select>
        <div style="padding: 10px;"></div>
        <el-image style="width: 80px;height: 160px;" fit="fill" v-if="perview_url" shape="square" :size="100"
          :src="perview_url" :preview-src-list="[perview_url]"></el-image>
        <div style="padding: 10px;"></div>
      </div>
      <div>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button @click="() => print_type === 'one' ? sumbit() : allPrints()">确认</el-button>
      </div>
    </el-dialog>
    <!-- 付款Dialog -->
    <pay-dialog :fromList="fromList" :dialogShow="dialogShow" @closePayDialog="closePayDialog"
      :name="orderItem.goods_name" :price="orderItem.order_price" :money="money" :orderId="orderItem.id" />
  </div>
</template>

<script>
import { getPackageList, getUserMoney, ddexptpl } from 'network/api'
import { timeConversion } from 'utils/utils'
import ChildTable from './ChildTable'
import PayDialog from 'components/payDialog/PayDialog'

import { request } from '@/network/request'
import { mapState } from 'vuex'

export default {
  components: {
    ChildTable,
    PayDialog
  },
  inject: ['mainReload'],
  data() {
    return {
      tab: '',
      // activeIndex: '0',
      // orderData: {}, // 包含订单列表的对象
      showDialog: false,
      packageData: {}, // 包裹对象
      order_no: '',
      dialogShow: false,
      orderItem: {},
      money: '',
      id: null,
      showDownload: false,
      fromList: true,
      url: '',
      dialogVisible: false,
      ddexptplInfo: null,
      printInfo: null,
      value: '',
      printAllInfo: null,
      print_type: 'one',
      /** @type String */
      ddexptplValue: '',
      perview_url: ''
    }
  },
  props: {
    orderData: Object // 包含订单列表的对象
    // eslint-disable-next-line vue/no-dupe-keys
    // activeIndex: String // 当前menu index
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion() {
      return timeConversion
    },
    ...mapState({
      printers: (state) => state.socket.printers
    })
  },
  created() {
    this.url = this.$url
  },
  methods: {
    orderSelect(selection, row) {
      console.log(selection)
      this.$nextTick(() => {
        if (selection.length > 0) {
          this.tab = selection[0].deliver_tag
        } else {
          this.tab = ''
        }
      })
    },
    ddexptplInfoChange() {
      if (this.ddexptplValue) {
        const obj = JSON.parse(this.ddexptplValue)
        this.perview_url = obj.image
      }
    },
    /** 打单多选 */
    handleSelectionChange(val) {
      this.printAllInfo = val
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 根据分页改变获取数据
    currentChange(val) {
      this.$emit('curChange', val)
    },
    // menu激活回调
    selectChange(index) {
      this.$emit('menuChange', index)
    },
    // 查看子列表
    eye(item) {
      this.order_no = item.order_no
      this.id = item.id
      this.showDialog = true
      // 获取包裹列表
      getPackageList({ order_no: this.order_no }).then((res) => {
        this.packageData = res.data
        if (this.packageData.data.length !== 0) {
          this.showDownload = true
        } else {
          this.showDownload = false
        }
      })
    },
    closeDialog() {
      this.showDialog = false
    },
    // 子列表分页更新数据更新
    updatePackageData(val) {
      getPackageList({
        order_no: this.order_no,
        page: val
      }).then((res) => {
        this.packageData = res.data
      })
    },
    // 取消
    remove(id) {
      this.$emit('remove', id)
    },
    download(data) {
      if (!data) {
        return
      }
      const BLOB = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(BLOB)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '分析文件.xlsx')
      document.body.appendChild(link)
      link.click()
    },
    // 支付
    payThis(data) {
      this.orderItem = data
      // 获取账户余额 并打开PayDialog
      getUserMoney().then((res) => {
        this.money = res.data.usermoney
        this.dialogShow = true
      })
    },
    // 关闭payDialog
    closePayDialog() {
      this.dialogShow = false
      this.mainReload() // 更新列表
    },
    /** 单个打印 */
    async individuallyPrint(row) {
      try {
        this.printInfo = row
        this.print_type = 'one'
        const res = await ddexptpl(4, row.deliver_tag)
        if (res.code === 1) {
          this.ddexptplInfo = res.data
        }
        await this.$store.commit('socket/doGetPrinters')
        this.dialogVisible = true
      } catch (e) {
        console.log(e)
      }
    },
    async sumbit() {
      try {
        /**
         * @type {{data: Array<{waybill: {data: {print_params: string, print_data: string, sign: string}[]}}>, code: number, msg: string}}
         * */
        const res = await request(`/api/tbbandstore/ddwaybills?packageid=${this.printInfo.id}`)
        // 获取选择的打印面单模板
        const obj = JSON.parse(this.ddexptplValue)
        if (res.code === 1) {
          const encrypt = res.data.map(item => {
            return item?.waybill?.data ?? null
          }).flat().map((item, index) => {
            if (item === null) return null
            return {
              signature: item.sign,
              params: item.print_params,
              encryptedData: item.print_data,
              templateURL: obj.url,
              address: {
                countryCode: 'CHN',
                cityName: this.printInfo.receive_city || '',
                detailAddress: this.printInfo.receive_address || '',
                districtName: this.printInfo.receive_town || '',
                provinceName: this.printInfo.receive_province || ''
              },
              contact: {
                mobile: this.printInfo.receive_phone || '',
                name: this.printInfo.receive_name || ''
              }
            }
          }).filter(item => item !== null)
          if (encrypt.length <= 0) {
            this.$message({
              type: 'warning',
              message: '导入打单失败'
            })
            return
          }
          this.$store.commit('socket/dayin', {
            printer: this.value,
            arr: encrypt,
            package_ids: printInfo.id
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
      } catch (e) {
        console.log(e)
        this.$message({
          type: 'error',
          message: JSON.stringify(e)
        })
      }
    },
    /** c */
    async allPrint() {
      await this.$store.commit('socket/doGetPrinters')

      const res = await ddexptpl(4, this.printAllInfo[0].deliver_tag)
      if (res.code === 1) {
        this.ddexptplInfo = res.data
      }
      this.dialogVisible = true
      this.print_type = 'all'
    },
    async allPrints() {
      try {
        await this.$store.commit('socket/doGetPrinters')
        // 获取选择的打印面单模板
        // eslint-disable-next-line no-unused-vars
        const obj = JSON.parse(this.ddexptplValue)

        if (this.printAllInfo) {
          // eslint-disable-next-line camelcase
          const package_ids = this.printAllInfo.map(item => {
            return item.id
          }).join(',')
          /**
           * @type {{data: Array<{waybill: {data: {print_params: string, print_data: string, sign: string}[]}}>, code: number, msg: string}}
           * */
          // eslint-disable-next-line camelcase
          const res = await request(`/api/tbbandstore/ddwaybills?packageid=${package_ids}`)
          let num = 0
          if (res.code === 1) {
            let encrypt = res.data.map(item => {
              return item.waybill?.data ?? null
            }).flat()
            encrypt = encrypt.filter(item => {
              if (item === null) num++
              return item !== null
            })
            const encryptArr = []
            for (let i = 0; i < encrypt.length; i++) {
              const item = encrypt[i]
              if (item) {
                encryptArr.push({
                  signature: item.sign,
                  params: item.print_params,
                  encryptedData: item.print_data,
                  templateURL: obj.url,
                  address: {
                    countryCode: 'CHN',
                    cityName: this.printAllInfo[i].receive_city || '',
                    detailAddress: this.printAllInfo[i].receive_address || '',
                    districtName: this.printAllInfo[i].receive_town || '',
                    provinceName: this.printAllInfo[i].receive_province || ''
                  },
                  contact: {
                    mobile: this.printAllInfo[i].receive_phone || '',
                    name: this.printAllInfo[i].receive_name || ''
                  }
                })
              }
            }
            this.$message({
              type: 'success',
              message: `${num === 0 ? this.printAllInfo.length - encryptArr.length === 0 ? num : this.printAllInfo.length - encryptArr.length : num}个包裹导入打单失败，${encryptArr.length}个包裹导入打单成功`
            })
            if (encryptArr.length <= 0) {
              return
            }
            this.$store.commit('socket/dayin', {
              printer: 'Microsoft Print to PDF',
              arr: encryptArr,
              package_ids: package_ids
            })
          } else {
            this.$message({
              type: 'warning',
              message: res.msg
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: '请选择订单'
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/orderTable.css"></style>

<style>
.table .el-table tbody tr:hover>td {
  background-color: #e6f7ff;
}

.el-table__fixed-header-wrapper .el-checkbox {
  display: none
}
</style>
